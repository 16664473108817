import logo from './logo.jpeg';
import './App.css';
import NewsletterForm from './NewsletterForm.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h1>Generative AI tools for corporate restructuring.</h1>
          </div>
          <div className="col-sm-6">
            <p>
            Leverage new tools built on large language models, prompt engineering, and code to increase creditor recoveries and stay informed.
            </p>
            <NewsletterForm />
          </div>
        </div>
        </div>
      </header>
      <footer>
        &copy; Ash Breeze LLC
      </footer>
    </div>
  );
}

export default App;
