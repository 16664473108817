import React, { useRef } from 'react';
import './NewsletterForm.css';
import emailjs from '@emailjs/browser';

const NewsletterForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e.target);
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE , e.target, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
          //$('#messages').removeClass('hide').addClass('alert alert-success alert-dismissible').slideDown().show();
          const el = document.querySelector("#messages");
          el.classList.remove("d-none");
        }, (error) => {
          console.log(error.text);
      });
    };

    return (
        <form onSubmit={sendEmail}>
            <div className="row">
            <div className="col-sm-10">
                <input type="email" class="form-control emailField" id="user_email" name="user_email" aria-describedby="emailHelp" placeholder="Enter email"></input>
                <div id="messages" className="fs-6 col d-none">
                    <i>Email Submitted.</i>
                </div>
            </div>
            <div className="col-sm-2">
                <button type="submit" className="btn submitButton align-top">Submit</button>
            </div>
            </div>
        </form>
    );
}

export default NewsletterForm
